/**
 * TODO: these async flows need to be revisited and cleaned up.  There are many inconsistencies
 * which cause bad UX, missing error handling, and some of the guards are difficult to grok.
 * In several places, additional logic should be delegated to store getters or actions. Simply
 * porting the existing logic for now... Going forward, options for error handling are:
 *   A. Do nothing -> next()
 *   B. Redirect to different page -> next({ name })
 *   C. Stay on same page and show error message -> dispatch(SHOW_CONFIRM_DIALOG, {}); next()
 *   D. Redirect to different page and show error message -> dispatch(SHOW_CONFIRM_DIALOG, {}); next({ name })
 */
import store from '@/store'
import {
  GET_CART,
  GET_IS_CHANGEABLE_SUBMITTED_ORDER,
  GET_SUBMITTED_ORDER,
  SET_INTAKE_LOAD_ERROR,
  UNDO_SUBMITTED_ORDER_FOR_UPDATE,
  VALIDATE_ORDER,
} from '@/store/mutation-types/orderMutations'
import {
  GET_RESTAURANT_MENU,
  GET_CONFIRMATION_RESTAURANT_MENU,
} from '@/store/mutation-types/restaurantMenuMutations'
import {
  GET_CONFIRMATION_RESTAURANT,
  GET_DELIVERY_RESTAURANTS,
  GET_RESTAURANT,
  GET_RESTAURANT_PICKUP_HOURS,
  GET_RESTAURANT_TIMES,
} from '@/store/mutation-types/restaurantDataMutations'
import { GET_CUSTOMER } from '@/store/mutation-types/userMutations'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { GET_EVENT_TYPES } from '@/store/mutation-types/eventTypeMutations'
import { GET_USER_ORDER_HISTORY } from '@/store/mutation-types/userOrderHistoryMutations'
import { GET_HOME_MENU } from '@/store/mutation-types/homeMenuMutations'
import { GET_ALL_REWARDS_DATA } from '@/store/mutation-types/rewardsMutations'
import { GET_POPULAR_BUILDS } from '@/store/mutation-types/popularBuildsMutations'
import { GET_ALL_SAVED_ADDRESSES } from '@/store/mutation-types/customerDeliveryMutations'
import { GET_WALLET } from '@/store/mutation-types/transactionMutations'
import * as routeNames from '@/router/routeNames'
import * as errorCodes from '@/constants/errorCodes'
import * as orderStatuses from '@/constants/orderStatuses'
import { matchesSomeErrorCodes } from '@/utilityFunctions'

/**
 * The purpose of custom errors is to return early from the following async flows.  Using ES5
 * syntax is necessary since babel <= 7 doesn't support extending Error (to maintain stack trace)
 * TODO: migrate to babel 7 and / or evaluate supporting async functions and cost to bundle size
 * which removes the need for custom errors entirely
 */
function ErrorUserLoggedIn() {}
ErrorUserLoggedIn.prototype = new Error()
function ErrorUserNotLoggedIn() {}
ErrorUserNotLoggedIn.prototype = new Error()
function ErrorUserNotRegistered() {}
ErrorUserNotRegistered.prototype = new Error()
function ErrorNoOrder() {}
ErrorNoOrder.prototype = new Error()
function ErrorNoOrderItems() {}
ErrorNoOrderItems.prototype = new Error()
function ErrorMenuItemUnavailable() {}
ErrorMenuItemUnavailable.prototype = new Error()
function ErrorUserEditingSameOrder() {}
ErrorUserEditingSameOrder.prototype = new Error()
function ErrorOrderBadStatus() {}
ErrorOrderBadStatus.prototype = new Error()
function ErrorNoRestaurantMenu() {}
ErrorNoRestaurantMenu.prototype = new Error()
function ErrorOrderNotEditable() {}
ErrorOrderNotEditable.prototype = new Error()
function ErrorOrderPastDue() {}
ErrorOrderPastDue.prototype = new Error()
function ErrorOrderItemDoesNotExist() {}
ErrorOrderItemDoesNotExist.prototype = new Error()

export function beforeOrderHistoryEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isFullUser) {
        throw new ErrorUserNotRegistered()
      }
      return Promise.all([
        store.dispatch(GET_USER_ORDER_HISTORY),
        store.dispatch(GET_HOME_MENU),
      ])
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      if (err instanceof ErrorUserNotRegistered) {
        return next({ name: routeNames.LOGIN, query: { prevPath: to.path } })
      }
      store.dispatch(SHOW_CONFIRM_DIALOG, {
        headerText: 'Order History',
        text: 'Something unexpected happened.',
        subText: 'Please try again or contact customer support.',
        okText: 'OK',
        hideCancel: true,
      })
      next()
    })
}

export function beforeCheckoutEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.orderHasItems) {
        throw new ErrorNoOrderItems()
      }
      if (!store.getters.restaurantMenu) {
        throw new ErrorNoRestaurantMenu()
      }
      const { storeNumber: restaurantNumber } = store.getters.orderRestaurant
      return store.dispatch(GET_RESTAURANT, restaurantNumber)
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      next({ name: routeNames.HOME })
    })
}

function beforeSignInToAccountEnter(to, from, next) {
  appInit()
    .then(() => {
      if (store.getters.isFullUser) {
        throw new ErrorUserNotRegistered()
      }
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      next({ name: routeNames.HOME })
    })
}

export const beforeCreateAccountEnter = beforeSignInToAccountEnter
export const beforeLoginEnter = beforeSignInToAccountEnter

export function beforeHomeEnter(to, from, next) {
  appInit()
    .then(() => {
      /**
       * Fire and forget these for now...
       * TODO: look into refactoring GET_HOME_MENU - very difficult to grok
       */
      store.dispatch(GET_HOME_MENU, to.query.zipCode).catch(() => {})
      store.dispatch(GET_POPULAR_BUILDS).catch(() => {})
    })
    .then(() => next())
    .catch((err) => handleValidateOrderError(err, next))
}

function beforeBuilderEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isIntakeInUpdateMode) {
        throw new ErrorNoOrder()
      }
      if (!store.getters.restaurantMenu) {
        throw new ErrorNoRestaurantMenu()
      }
      if (
        to.params.orderItemId &&
        !store.getters.orderItems.some(
          (oi) => oi.orderItemId === to.params.orderItemId,
        )
      ) {
        throw new ErrorOrderItemDoesNotExist()
      }
      const menuItem = store.getters.restaurantMenu.find(
        (m) => m.id === to.params.menuType,
      )
      const menuItemIsAvailable = !!menuItem && menuItem.price > 0
      if (!menuItemIsAvailable) {
        throw new ErrorMenuItemUnavailable()
      }
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      if (err instanceof ErrorNoOrder) {
        return next({
          name: routeNames.INTAKE_DETAIL,
          query: { menuItemId: to.params.menuType },
        })
      }
      next({ name: routeNames.HOME })
    })
}

export const beforeBuildYourOwnEnter = beforeBuilderEnter
export const beforeBurritosByTheBoxEnter = beforeBuilderEnter
export const beforeChipsAndSalsaEnter = beforeBuilderEnter

export function beforePageNotFoundEnter(to, from, next) {
  appInit()
    .then(() => next())
    .catch((err) => handleValidateOrderError(err, next))
}

function beforeAccountPageEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isFullUser) {
        throw new ErrorUserNotRegistered()
      }
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      next({ name: routeNames.LOGIN, query: { prevPath: to.path } })
    })
}

export const beforeUpdateUserEnter = beforeAccountPageEnter
export const beforeChangePasswordEnter = beforeAccountPageEnter

export function beforePaymentMethodsEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isFullUser) {
        throw new ErrorUserNotRegistered()
      }
      return store.dispatch(GET_WALLET)
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      if (err instanceof ErrorUserNotRegistered) {
        return next({ name: routeNames.LOGIN, query: { prevPath: to.path } })
      }
      next()
    })
}

export function beforeSavedAddressesEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isFullUser) {
        throw new ErrorUserNotRegistered()
      }
      return store.dispatch(GET_ALL_SAVED_ADDRESSES)
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      if (err instanceof ErrorUserNotRegistered) {
        return next({ name: routeNames.LOGIN, query: { prevPath: to.path } })
      }
      next()
    })
}

export function beforeConfirmationEnter(to, from, next) {
  appInit()
    .then(() => {
      if (!store.getters.isLoggedIn) {
        throw new ErrorUserNotLoggedIn()
      }
      if (
        store.getters.isInEdit &&
        to.params.orderId === store.getters.currentUser.cateringEditOrderId
      ) {
        throw new ErrorUserEditingSameOrder()
      }
      return store.dispatch(GET_SUBMITTED_ORDER, to.params.orderId)
    })
    .then(() => {
      const { submittedOrder: order } = store.getters
      const { storeNumber } = order.orderDetail.store
      if (
        [orderStatuses.CANCELED, orderStatuses.CLOSED].includes(order.status)
      ) {
        throw new ErrorOrderBadStatus()
      }
      const promises = [
        store.dispatch(GET_IS_CHANGEABLE_SUBMITTED_ORDER, to.params.orderId),
        store.dispatch(GET_CONFIRMATION_RESTAURANT_MENU, storeNumber),
      ]
      if (!order.orderDetail.isDelivery) {
        promises.push(store.dispatch(GET_CONFIRMATION_RESTAURANT, storeNumber))
      }
      return Promise.all(promises)
    })
    .then(() => next())
    .catch((err) => {
      if (handleValidateOrderError(err, next)) {
        return
      }
      if (err instanceof ErrorUserNotLoggedIn) {
        return next({ name: routeNames.LOGIN, query: { prevPath: to.path } })
      }
      if (err instanceof ErrorUserEditingSameOrder) {
        return next({ name: routeNames.CHECKOUT })
      }
      if (err instanceof ErrorOrderBadStatus) {
        return next({ name: routeNames.HOME })
      }
      store.dispatch(SHOW_CONFIRM_DIALOG, {
        text: 'Order not Found',
        subText: "Uh oh, we can't find this order",
        okText: 'OK',
        hideCancel: true,
      })
      next({ name: routeNames.HOME })
    })
}

export function beforeIntakeDetailEnter(to, from, next) {
  const getRestaurantTimes = (restaurantNumbers, pickupDate, isDelivery) => {
    return store
      .dispatch(GET_RESTAURANT_TIMES, {
        restaurantNumbers,
        pickupDate,
        isDelivery,
      })
      .catch(() => {
        return store.dispatch(SHOW_CONFIRM_DIALOG, {
          headerText: 'Restaurant Times',
          text: 'There are no available times for the selected date.',
          subText: isDelivery
            ? 'Please choose a different date or delivery address.'
            : 'Please choose a different date or restaurant.',
          hideCancel: true,
        })
      })
  }
  appInit()
    .then(() => {
      const { intakeInfo, isFullUser } = store.getters
      if (isFullUser) {
        // fire and forget requests for addresses saved by the user
        store.dispatch(GET_ALL_SAVED_ADDRESSES).catch(() => {})
      }
      const promises = [store.dispatch(GET_EVENT_TYPES).catch(() => {})]
      if (intakeInfo && intakeInfo.isPickup) {
        promises.push(
          store
            .dispatch(GET_RESTAURANT, intakeInfo.savedRestaurantId)
            .catch(() => {}),
          getRestaurantTimes(
            [intakeInfo.savedRestaurantId],
            intakeInfo.pickupDate ? intakeInfo.pickupDate.toDateString() : null,
            !intakeInfo.isPickup,
          ),
          store
            .dispatch(GET_RESTAURANT_PICKUP_HOURS, intakeInfo.savedRestaurantId)
            .catch(() => {}),
        )
      } else if (intakeInfo && !intakeInfo.isPickup) {
        const { latitude, longitude } = intakeInfo.deliveryAddress
        promises.push(
          store
            .dispatch(GET_DELIVERY_RESTAURANTS, { latitude, longitude })
            .catch((err) => store.dispatch(SET_INTAKE_LOAD_ERROR, err))
            .then(() => {
              let pickupDate = new Date(intakeInfo.pickupDate)
              if (!isNaN(pickupDate.getTime())) {
                pickupDate = new Date(
                  pickupDate.getFullYear(),
                  pickupDate.getMonth(),
                  pickupDate.getDate(),
                )
              }
              const selectedRealHours = pickupDate
                ? store.getters.restaurantDeliveryRealHours.filter(
                    (hours) => hours.date.getTime() === pickupDate.getTime(),
                  )
                : []
              /**
               * TODO: investigate the following... Previous comment said:
               *   If selectedRealHours is empty, then the date is invalid.
               *   VALIDATE_ORDER will handle this, and the date error will display.
               *   No need to handle here.
               */
              const realHours = selectedRealHours.length
                ? selectedRealHours[0]
                : null
              if (
                realHours &&
                realHours.restaurantNumbers &&
                realHours.restaurantNumbers.length
              ) {
                return getRestaurantTimes(
                  realHours.restaurantNumbers,
                  intakeInfo.pickupDate
                    ? intakeInfo.pickupDate.toDateString()
                    : null,
                  !intakeInfo.isPickup,
                )
              }
            }),
        )
      }
      return Promise.all(promises)
    })
    .then(() => next())
    .catch((err) => handleValidateOrderError(err, next))
}

function appInit() {
  return (
    store
      .dispatch(GET_CUSTOMER)
      .then(() => {
        if (store.getters.isFullUser) {
          // fire and forget requests for rewards
          store.dispatch(GET_ALL_REWARDS_DATA).catch(() => {})
        }
        return store.dispatch(GET_CART)
      })
      // failures expected when user not logged in or user has no cart
      .catch(() => {})
      /**
       * TODO: VALIDATE_ORDER seems like leakage of server details onto client and probably should
       * be killed off.  UNDO_SUBMITTED_ORDER_FOR_UPDATE could just be a scheduled process on the
       * server rather than client driven to cleanup cart orders which are past due or un-editable?
       */
      .then(() => store.dispatch(VALIDATE_ORDER))
      .catch((err) => {
        const hasUneditableError = matchesSomeErrorCodes(
          [
            errorCodes.ERROR_VALIDATION_UPDATE_BAD_STATUS,
            errorCodes.ERROR_VALIDATION_PAYMENTS_ALREADY_MADE,
          ],
          err,
        )
        const hasPastDueError = matchesSomeErrorCodes(
          [
            errorCodes.ERROR_VALIDATION_TOO_CLOSE_TO_DELIVERY,
            errorCodes.ERROR_VALIDATION_TOO_CLOSE_TO_PICKUP,
          ],
          err,
        )
        const shouldUndoEdit =
          store.getters.isInEdit && (hasUneditableError || hasPastDueError)
        if (!shouldUndoEdit) {
          return
        }
        return store
          .dispatch(UNDO_SUBMITTED_ORDER_FOR_UPDATE)
          .then(() => {
            if (hasUneditableError) {
              throw new ErrorOrderNotEditable()
            }
            if (hasPastDueError) {
              throw new ErrorOrderPastDue()
            }
          })
          .catch((err) => {
            if (
              err instanceof ErrorOrderNotEditable ||
              err instanceof ErrorOrderPastDue
            ) {
              throw err
            }
          })
      })
      .then(() => {
        const { orderRestaurant } = store.getters
        if (!orderRestaurant) {
          return
        }
        return store
          .dispatch(GET_RESTAURANT_MENU, orderRestaurant.storeNumber)
          .catch(() => {})
      })
  )
}

function handleValidateOrderError(err, next) {
  if (err instanceof ErrorOrderNotEditable) {
    next({ name: routeNames.HOME })
    return true
  }
  if (err instanceof ErrorOrderPastDue) {
    store.dispatch(SHOW_CONFIRM_DIALOG, {
      headerText: 'Edit Order',
      text: 'NEED TO EDIT YOUR ORDER?',
      subText:
        "You're not able to edit your order online within 24 hours of its pickup/delivery time. Please call us at 1-800-CHIPOTLE to edit this order.",
      okText: 'OK',
      hideCancel: true,
    })
    next({ name: routeNames.HOME })
    return true
  }
  return false
}
