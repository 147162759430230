export const ERROR_VALIDATION = 'COO-0020' // General validation error
export const ERROR_VALIDATION_OWNED_BY_OTHER_USER = 'COO-0022' // user attempted to access order they do not own
export const ERROR_VALIDATION_UPDATE_BAD_STATUS = 'COO-0027' // Order no longer editable based on its status
export const ERROR_VALIDATION_MISSING_PAYMENT_INFO = 'COO-0029' // Payment information is missing
export const ERROR_MENU_NOT_AVAILABLE = 'COO-0033' // menu item not available at restaurant
export const ERROR_VALIDATION_INVALID_PAYMENT_ZIPCODE = 'COO-0036' // Zipcode is invalid format
export const ERROR_PICKUP_DATE_UNAVAILABLE = 'COO-0055' // pickup date unavailable
export const ERROR_PICKUP_TIME_UNAVAILABLE = 'COO-0056' // pickup time unavailable
export const ERROR_DELIVERY_DATE_UNAVAILABLE = 'COO-0068' // delivery date unavailable
export const ERROR_DELIVERY_TIME_UNAVAILABLE = 'COO-0069' // delivery time unavailable
export const ERROR_VALIDATION_EXCEEDS_DELIVERY_MAX = 'COO-0070' // Order exceeds delivery maximum
export const ERROR_REST_NOT_SUPPORT_CATERING = 'COO-0078' // validation restaurant does not support catering
export const ERROR_VALIDATION_PAYMENTS_ALREADY_MADE = 'COO-0080' // Payment already applied to order, cannot change order
export const ERROR_VALIDATION_TOO_CLOSE_TO_DELIVERY = 'COO-0081' // Order too close to delivery date
export const ERROR_VALIDATION_TOO_CLOSE_TO_PICKUP = 'COO-0082' // Order too close to pickup date
export const ERROR_VALIDATION_ORDER_ITEM_MENU_PRICE_CHANGED = 'COO-0083' // Menu price has changed
export const ERROR_PAYMENT_PROCESSING = 'COO-0102' // error processing payment
export const ERROR_PAYMENT_AUTH_FAILED = 'COO-0103' // card auth failed
export const ERROR_ADDRESS_UNDELIVERABLE = 'COO-0108' // No restaurants found nearby that can delivery to address
export const ERROR_RESTAURANT_NOT_FOUND = 'COO-0109' // restaurant not found
export const ERROR_REST_NO_LONGER_SUPPORT_CATERING = 'COO-0111' // restaurant no longer supports catering
export const ERROR_PROMO_CODE_INVALID = 'COO-0115' // Promo code is invalid
export const ERROR_PROMO_CODE_MISSING_ITEMS = 'COO-0116' // Promo code missing required order items
export const ERROR_PROMO_CODE_EXPIRED = 'COO-0117' // Promo code expired
export const ERROR_PROMO_CODE_MAX_LIMIT_REACHED = 'COO-0118' // Max reserve limit reached for promotion.
export const ERROR_SECURE_DELIVERY = 'COO-0122' // failed to secure delivery
export const ERROR_VALIDATION_TOKEN_MAX_COUNT = 'COO-0130' // Exceeded max payment card count limit
export const ERROR_VALIDATION_TOKEN_DUPLICATE = 'COO-0131' // Payment card already exists
export const ERROR_VALIDATION_PAYMENT_CANNOT_BE_PROCESSED = 'COO-0150' // Payment cannot be processed

export const ERROR_SERVICE_DEPENDENCY_UNAVAILABLE = 'COO-9000' // Service Dependency Unavailable

export const ERROR_VALIDATION_DELIVERY_TIP_EXCEEDS_MAX = 'DTF-0010'

export const ERROR_NO_DELIVERY_COVERAGE = 'CGD-0101' // No delivery coverage for address
export const ERROR_DELIVERY_QUOTE_NOT_AVAILABLE = 'CGD-0104' // Delivery quote not available

export const ERROR_PHONE_NUMBER_IN_USE = 'CAU-0056' // Phone number already registered
export const ERROR_OLD_PASSWORD_USED = 'CAU-0075' // Old password used
export const ERROR_VALIDATION_CURRENT_PASSWORD_USED = 'CAU-0078' // Current password used
export const ERROR_CLEAR_WALLET_FAILED = 'CAU-0530' // Password reset but wallet clear failed

export const ERROR_EMAIL_IN_USE = 'CAU-0220' //email already exists or in use

export const ERROR_VERIFICATION_REGISTRATION_NEEDED = 'CAU-0111' // 2SV Verification for create new account
export const ERROR_VERIFICATION_LOGIN_NEEDED = 'CAU-0110' // 2SV Verification for login
export const ERROR_VERIFICATION_INVALID_CODE = 'CAU-0113' // 2SV Verification invalid token entered
export const ERROR_VERIFICATION_ACCOUNT_LOCKED = 'CAU-0112' // 2SV Too many failed verification attempts. account is locked
export const ERROR_VERIFICATION_CODE_EXPIRED = 'CAU-0115' // 2SV Token has expired
export const ERROR_VERIFICATION_UPDATE_EMAIL_NEEDED = 'CUS-0401' // 2SV Verification for updating email
export const ERROR_VERIFICATION_UPDATE_PHONE_NEEDED = 'CUS-0400' // 2SV Verification for updating phone number
export const ERROR_UPDATE_PHONE_NUMBER_IN_USE = 'CUS-0056' // Phone number used too many times
export const ERROR_UPDATE_EMAIL_IN_USE = 'CUS-0006' //email already exists or in use
export const ERROR_VERIFICATION_PASSWORD_FAILED = 'CAU-0320' // 2SV Verification Initialization/password verification error for update phone number flow
