export const ResultDisplayTypes = Object.freeze({
  List: 1,
  Map: 2,
  Both: 3,
})

export const BuilderInputCountTypes = Object.freeze({
  Select: 1,
  Textbox: 2,
})

export const HomeMenuIds = Object.freeze({
  BuildYourOwnIds: ['CMG-4105', 'CMG-4206', 'CMG-4306'],
  BurritosByTheBoxId: 'CMG-4012',
  ChipsAndSalsaId: 'CMG-4027',
})

export const CacheTimes = Object.freeze({
  ExtraShort: 10000, // 10 seconds
  Short: 30000, // 30 seconds
  Medium: 300000, // 5 minutes
  Long: 1800000, // 30 minutes
})

export const SALSA = 'CMG-4123'

const builderFoodImageList = {}
builderFoodImageList['CMG-4307'] = require('@/static/img/food/white-rice.png')
builderFoodImageList['CMG-4106'] = require('@/static/img/food/white-rice.png')
builderFoodImageList['CMG-4207'] = require('@/static/img/food/white-rice.png')
builderFoodImageList['CMG-5001'] = require('@/static/img/food/white-rice.png')
builderFoodImageList['CMG-4308'] = require('@/static/img/food/brown-rice.png')
builderFoodImageList['CMG-4107'] = require('@/static/img/food/brown-rice.png')
builderFoodImageList['CMG-4208'] = require('@/static/img/food/brown-rice.png')
builderFoodImageList['CMG-5002'] = require('@/static/img/food/brown-rice.png')
builderFoodImageList['CMG-4310'] = require('@/static/img/food/black-beans.png')
builderFoodImageList['CMG-4109'] = require('@/static/img/food/black-beans.png')
builderFoodImageList['CMG-4210'] = require('@/static/img/food/black-beans.png')
builderFoodImageList['CMG-4311'] = require('@/static/img/food/pinto-beans.png')
builderFoodImageList['CMG-4110'] = require('@/static/img/food/pinto-beans.png')
builderFoodImageList['CMG-4211'] = require('@/static/img/food/pinto-beans.png')
builderFoodImageList[
  'CMG-4309'
] = require('@/static/img/food/salad-lettuce.png')
builderFoodImageList[
  'CMG-4108'
] = require('@/static/img/food/salad-lettuce.png')
builderFoodImageList[
  'CMG-4209'
] = require('@/static/img/food/salad-lettuce.png')

builderFoodImageList['CMG-4312'] = require('@/static/img/food/chicken.png')
builderFoodImageList['CMG-4111'] = require('@/static/img/food/chicken.png')
builderFoodImageList['CMG-4212'] = require('@/static/img/food/chicken.png')
builderFoodImageList['CMG-4002'] = require('@/static/img/food/chicken.png')
builderFoodImageList['CMG-4314'] = require('@/static/img/food/carnitas.png')
builderFoodImageList['CMG-4113'] = require('@/static/img/food/carnitas.png')
builderFoodImageList['CMG-4214'] = require('@/static/img/food/carnitas.png')
builderFoodImageList['CMG-4003'] = require('@/static/img/food/carnitas.png')
builderFoodImageList['CMG-4313'] = require('@/static/img/food/steak.png')
builderFoodImageList['CMG-4112'] = require('@/static/img/food/steak.png')
builderFoodImageList['CMG-4213'] = require('@/static/img/food/steak.png')
builderFoodImageList['CMG-4001'] = require('@/static/img/food/steak.png')
builderFoodImageList['CMG-4315'] = require('@/static/img/food/barbacoa.png')
builderFoodImageList['CMG-4114'] = require('@/static/img/food/barbacoa.png')
builderFoodImageList['CMG-4215'] = require('@/static/img/food/barbacoa.png')
builderFoodImageList['CMG-4004'] = require('@/static/img/food/barbacoa.png')
builderFoodImageList['CMG-4316'] = require('@/static/img/food/sofritas.png')
builderFoodImageList['CMG-4115'] = require('@/static/img/food/sofritas.png')
builderFoodImageList['CMG-4216'] = require('@/static/img/food/sofritas.png')
builderFoodImageList['CMG-4006'] = require('@/static/img/food/sofritas.png')
builderFoodImageList['CMG-4317'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4116'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4217'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4029'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4005'] = require('@/static/img/food/guacamole.png') // BBTB Veggie

builderFoodImageList[
  'CMG-4319'
] = require('@/static/img/food/fajita-veggies.png')
builderFoodImageList[
  'CMG-4118'
] = require('@/static/img/food/fajita-veggies.png')
builderFoodImageList[
  'CMG-4219'
] = require('@/static/img/food/fajita-veggies.png')
builderFoodImageList[
  'CMG-4320'
] = require('@/static/img/food/shredded-cheese.png')
builderFoodImageList[
  'CMG-4119'
] = require('@/static/img/food/shredded-cheese.png')
builderFoodImageList[
  'CMG-4220'
] = require('@/static/img/food/shredded-cheese.png')
builderFoodImageList['CMG-4321'] = require('@/static/img/food/sour-cream.png')
builderFoodImageList['CMG-4120'] = require('@/static/img/food/sour-cream.png')
builderFoodImageList['CMG-4221'] = require('@/static/img/food/sour-cream.png')
builderFoodImageList['CMG-4318'] = require('@/static/img/food/taco-lettuce.png')
builderFoodImageList['CMG-4117'] = require('@/static/img/food/taco-lettuce.png')
builderFoodImageList['CMG-4218'] = require('@/static/img/food/taco-lettuce.png')

builderFoodImageList['CMG-4322'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4121'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4222'] = require('@/static/img/food/guacamole.png')
builderFoodImageList['CMG-4323'] = require('@/static/img/food/queso.png')
builderFoodImageList['CMG-4122'] = require('@/static/img/food/queso.png')
builderFoodImageList['CMG-4223'] = require('@/static/img/food/queso.png')
builderFoodImageList['CMG-4028'] = require('@/static/img/food/queso.png')
builderFoodImageList['CMG-4131'] = require('@/static/img/food/white-queso.png')
builderFoodImageList['CMG-4132'] = require('@/static/img/food/white-queso.png')
builderFoodImageList['CMG-4133'] = require('@/static/img/food/white-queso.png')
builderFoodImageList['CMG-4032'] = require('@/static/img/food/white-queso.png')

builderFoodImageList[
  'CMG-4324'
] = require('@/static/img/food/fresh-tomato-salsa.png')
builderFoodImageList[
  'CMG-4123'
] = require('@/static/img/food/fresh-tomato-salsa.png')
builderFoodImageList[
  'CMG-4224'
] = require('@/static/img/food/fresh-tomato-salsa.png')
builderFoodImageList[
  'CMG-4326'
] = require('@/static/img/food/roasted-chili-corn-salsa.png')
builderFoodImageList[
  'CMG-4125'
] = require('@/static/img/food/roasted-chili-corn-salsa.png')
builderFoodImageList[
  'CMG-4226'
] = require('@/static/img/food/roasted-chili-corn-salsa.png')
builderFoodImageList[
  'CMG-4325'
] = require('@/static/img/food/tomatillo-green-chili-salsa.png')
builderFoodImageList[
  'CMG-4124'
] = require('@/static/img/food/tomatillo-green-chili-salsa.png')
builderFoodImageList[
  'CMG-4225'
] = require('@/static/img/food/tomatillo-green-chili-salsa.png')
builderFoodImageList[
  'CMG-4327'
] = require('@/static/img/food/tomatillo-red-chili-salsa.png')
builderFoodImageList[
  'CMG-4126'
] = require('@/static/img/food/tomatillo-red-chili-salsa.png')
builderFoodImageList[
  'CMG-4227'
] = require('@/static/img/food/tomatillo-red-chili-salsa.png')

builderFoodImageList[
  'CMG-4328'
] = require('@/static/img/food/crispy-corn-taco-shells.png')
builderFoodImageList[
  'CMG-4127'
] = require('@/static/img/food/crispy-corn-taco-shells.png')
builderFoodImageList[
  'CMG-4228'
] = require('@/static/img/food/crispy-corn-taco-shells.png')
builderFoodImageList[
  'CMG-4329'
] = require('@/static/img/food/soft-flour-tortillas.png')
builderFoodImageList[
  'CMG-4128'
] = require('@/static/img/food/soft-flour-tortillas.png')
builderFoodImageList[
  'CMG-4229'
] = require('@/static/img/food/soft-flour-tortillas.png')

export const BuilderFoodImageList = Object.freeze(builderFoodImageList)

const builderReadonlyFoodImageList = {}
builderReadonlyFoodImageList[
  'CMG-4109'
] = require('@/static/img/food/readonly/black-beans.png') // Black Beans
builderReadonlyFoodImageList[
  'CMG-4119'
] = require('@/static/img/food/readonly/cheese.png') // Cheese
builderReadonlyFoodImageList[
  'CMG-4123'
] = require('@/static/img/food/readonly/mild-tomato.png') // Mild Tomato
builderReadonlyFoodImageList[
  'CMG-4027'
] = require('@/static/img/food/readonly/chips.png') // Chips
builderReadonlyFoodImageList[
  'CMG-4124'
] = require('@/static/img/food/readonly/medium-green.png') // Medium Green
builderReadonlyFoodImageList[
  'CMG-4121'
] = require('@/static/img/food/readonly/guacamole.png') // Guacamole
builderReadonlyFoodImageList[
  'CMG-4120'
] = require('@/static/img/food/readonly/sour-cream.png') // Sour Cream
builderReadonlyFoodImageList[
  'CMG-4326'
] = require('@/static/img/food/readonly/corn.png') // Corn
builderReadonlyFoodImageList[
  'CMG-4327'
] = require('@/static/img/food/readonly/hot-red.png') // Hot Red

export const BuilderReadonlyFoodImageList = Object.freeze(
  builderReadonlyFoodImageList,
)

const builderReadonlySalsasFoodImageList = {}
builderReadonlySalsasFoodImageList[
  'CMG-4123'
] = require('@/static/img/food/readonly/mild-tomato-for-salsas.png') // Mild Tomato
builderReadonlySalsasFoodImageList[
  'CMG-4224'
] = require('@/static/img/food/readonly/mild-tomato-for-salsas.png') // Mild Tomato
builderReadonlySalsasFoodImageList[
  'CMG-4324'
] = require('@/static/img/food/readonly/mild-tomato-for-salsas.png') // Mild Tomato
builderReadonlySalsasFoodImageList[
  'CMG-4124'
] = require('@/static/img/food/readonly/medium-green-for-salsas.png') // Medium Green
builderReadonlySalsasFoodImageList[
  'CMG-4326'
] = require('@/static/img/food/readonly/corn.png') // Corn
builderReadonlySalsasFoodImageList[
  'CMG-4327'
] = require('@/static/img/food/readonly/hot-red.png') // Hot Red

export const BuilderReadonlySalsasFoodImageList = Object.freeze(
  builderReadonlySalsasFoodImageList,
)

const veganItemList = {}
veganItemList['CMG-4121'] = true
veganItemList['CMG-4222'] = true
veganItemList['CMG-4322'] = true
veganItemList['CMG-4115'] = true
veganItemList['CMG-4216'] = true
veganItemList['CMG-4316'] = true
veganItemList['CMG-4116'] = true
veganItemList['CMG-4217'] = true
veganItemList['CMG-4317'] = true
veganItemList['CMG-4029'] = true

export const VeganItemList = Object.freeze(veganItemList)

// validation error names from the order validation endpoint
const orderValidationNameList = {}
orderValidationNameList['restaurantMissing'] = 'store'
orderValidationNameList['restaurantInvalid'] = 'restaurantNumber'
orderValidationNameList['deliveryDate'] = 'deliveryDate'
orderValidationNameList['deliveryTime'] = 'deliveryTime'
orderValidationNameList['pickupDate'] = 'pickupDate'
orderValidationNameList['pickupTime'] = 'pickupTime'

orderValidationNameList['menuItem'] = 'menuId'

export const OrderValidationNameList = Object.freeze(orderValidationNameList)

export const popularBuildsImageList = Object.freeze({
  'A8E80955-C741-4665-933A-26FF432BADCA': require('@/static/img/office-celebration.png'),
  '4D7994D9-1A6A-41BE-B53A-78FC32B9770A': require('@/static/img/working-lunch.png'),
  'C6C9571A-719F-4644-A7B9-B5B6054D9774': require('@/static/img/house-party.png'),
  '9AFF7E2F-CC1D-4567-A29D-7B2A230BF0F9': require('@/static/img/popular-builds/pb-plant-powered.jpg'),
  '0AA1E2A2-25F3-447D-AEA7-4DCAD15B5FBF': require('@/static/img/popular-builds/pb-work-anywhere.jpg'),
  'C9286E2C-32DD-45CA-9D06-B9D481C94714': require('@/static/img/popular-builds/pb-small-get-together.jpg'),
  'EE1FA794-DD59-457F-A085-B23F50CE9AE7': require('@/static/img/popular-builds/pb-bbtb.jpg'),
})

// For generic catch all errors use this and override after the spread.
export const UnexpectedError = Object.freeze({
  headerText: '',
  hideCancel: true,
  okText: 'OK',
  subText: 'Please try again or contact customer support.',
  text: 'Something unexpected happened.',
})

export const PhoneVerificationUnexpectedError = Object.freeze({
  headerText: 'Account Verification Error',
  hideCancel: true,
  okText: 'OK',
  subText: 'Please try again or continue as a guest.',
  text: 'Something unexpected happened.',
})

export const PhoneVerificationMultipleNumbersError = Object.freeze({
  headerText: 'Account Verification Error',
  hideCancel: true,
  okText: 'OK',
  subText: '',
  text: 'Number already in use. Try another?',
})

export const AccountLockedError = Object.freeze({
  text: 'Temporarily Locked',
  subText: [
    "For security reasons we're not allowing you to sign in right now. Try again in 30 minutes.",
    'Good thing you can still order Chipotle as a guest.',
  ],
  okText: 'OKAY',
  hideCancel: true,
})

export const CancelPhoneVerificationWarning = Object.freeze({
  text: 'Are you sure you want to leave?',
  subText:
    'If you leave before completing this process, the information you entered will not be saved.',
  okText: 'RESUME',
  cancelText: 'LEAVE',
})
