var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-info-control"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSubmit.apply(null, arguments)}}},[_c('inputbox',{attrs:{"id":_vm.id + 'uiFirstNameInput',"type":"text","placeholder":"Enter your first name","auto-complete":"given-name","required":true,"validity-state":_vm.validityState,"max-length":35,"rules":[_vm.rules.required('First Name is required')]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('inputbox',{attrs:{"id":_vm.id + 'uiLastNameInput',"type":"text","placeholder":"Enter your last name","auto-complete":"family-name","required":true,"validity-state":_vm.validityState,"max-length":50,"rules":[_vm.rules.required('Last Name is required')]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('inputbox',{attrs:{"id":_vm.id + 'uiEmailInput',"type":"text","placeholder":"Enter your email","auto-complete":"username","required":true,"validity-state":_vm.validityState,"max-length":50,"rules":[
          _vm.rules.required('Email is required'),
          _vm.rules.emailRegEx('Email is invalid', false, _vm.$mq),
        ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('inputbox',{attrs:{"id":_vm.id + 'uiPhoneNumberInput',"type":"text","placeholder":"Enter your phone number","required":true,"validity-state":_vm.validityState,"mask":_vm.phoneMask,"rules":[
          _vm.rules.required('Enter your phone number'),
          _vm.rules.phoneNumber('Phone Number must contain 10 digits'),
        ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('p',{staticClass:"small-text"},[_vm._v(" Enter a valid mobile number that can accept SMS messages. This will be used for two-step verification for account access, and for order-related notifications. ")]),_c('b-form-group',{staticClass:"form-input-group-label",attrs:{"label":"Country*","label-text-align":"left"}},[_c('b-form-select',{staticClass:"pl-0",attrs:{"id":_vm.id + 'uiCountrySelect',"options":_vm.countryCodes},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('inputbox',{attrs:{"id":_vm.id + 'uiPasswordInput',"type":_vm.passwordFieldType,"placeholder":"Enter your password","auto-complete":"new-password","required":true,"validity-state":_vm.validityState,"max-length":50,"rules":[
          _vm.rules.required('Password is required'),
          (v) =>
            _vm.rules.evaluatePassword(v) >= 2 ||
            'Password must have at least 8 characters and a minimum of one lowercase letter, one uppercase letter, one number, and one special character',
        ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('span',{attrs:{"slot":"icon"},slot:"icon"},[_c('eye-open-svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.shouldShowPassword),expression:"!shouldShowPassword"}],attrs:{"aria-label":"Show password","aria-hidden":_vm.shouldShowPassword,"role":"button","tabindex":"0"},on:{"eyeopensvg-click":_vm.showPassword},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.showPassword.apply(null, arguments)}}}),_c('eye-closed-svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowPassword),expression:"shouldShowPassword"}],attrs:{"aria-label":"Hide password","aria-hidden":!_vm.shouldShowPassword,"role":"button","tabindex":"0"},on:{"eyeclosedsvg-click":_vm.hidePassword},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.hidePassword.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"error-container error"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('a',{staticClass:"privacy-policy d-block",attrs:{"href":"https://www.chipotle.com/privacy-policy","target":"_blank"}},[_vm._v(" PRIVACY POLICY ")]),_c('b-button',{staticClass:"continue-btn",attrs:{"variant":"primary","block":"","size":"md","type":"submit","disabled":_vm.isSubmitInProgress}},[_vm._v(" CREATE ACCOUNT ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }