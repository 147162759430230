// TODO: evaluate discrepancies between this and customer auth service, simply porting for now
export default {
  isEmailValid: (email, isLogin = false) => {
    // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
    // Regex from above, and added restrictions to follow CrowdTwist validation rules
    // https://docs.oracle.com/en/cloud/saas/marketing/crowdtwist-develop/Developers/UserFormattingRules.html

    let regex = /^[\w%-]+(?:\.[\w%+-]+)*@(?:[A-Z0-9][A-Z0-9-]+\.)+([A-Z]){2,6}$/i;
    if(isLogin){
      regex = /^[\w%+-]+(?:\.[\w%+-]+)*@(?:[A-Z0-9][A-Z0-9-]+\.)+([A-Z]){2,6}$/i;
    }
    if  (regex.test(email)) {
      // Verify top-level domain doesn't repeat
      const hostname = email.split('@');
      const domains = hostname[1].split('.');
      return domains[domains.length - 1] !== domains[domains.length - 2];
    }

    return false;
  },
  // phone number must have at least 10 numeric digits regardless of other characters
  isPhoneNumberValid: (phone) => {
    return phone.replace(/[^0-9]/g, '').length >= 10
  },
  // currently we have the following phases
  //  1 = does not meet criteria
  //  2 = passes our criteria but weak
  //  3 = passes our criteria and entropy >= 32
  //  4 = passes our criteria and entropy >= 64
  evaluatePassword: (password) => {
    let score = 1
    let scoreSuccess = true

    const rules = getPasswordCriteria()
    if (!password) {
      return 0
    }

    const entropy = getEntropy(password)

    for (let r = 0; r < rules.length; r++) {
      const regex = new RegExp(rules[r].Pattern, 'g')
      const match = regex.exec(password)
      let testSuccess = match !== null

      // all Enforced rules must pass for overall success
      if (rules[r].Enforced && !testSuccess) {
        return 0
      }

      if (scoreSuccess) {
        // both entropy and regex must pass for a rule to be successful
        if (testSuccess) {
          testSuccess = entropy >= parseInt(rules[r].Entropy)
        }

        // a new grade is reached when ALL tests for that grade have passed
        if (testSuccess) {
          score = rules[r].Grade
        } else {
          score = rules[r].Grade - 1
          scoreSuccess = false
        }
      }
    }

    return score
  },
}

function getEntropy(phrase) {
  const uniqueSymbols = []
  let N = 0 // N = count of potential symbols from pools used

  const charSet = [
    { regex: /[a-z]/, value: 26 },
    { regex: /[A-Z]/, value: 26 },
    { regex: /[0-9]/, value: 10 },
    { regex: /[^a-zA-Z0-9]/, value: 32 },
  ]

  for (let i = 0; i < charSet.length; i++) {
    const set = charSet[i]
    if (set.regex.test(phrase)) {
      N += set.value
    }
  }

  for (let c = 0; c < phrase.length; c++) {
    if (uniqueSymbols.indexOf(phrase[c]) === -1) {
      uniqueSymbols.push(phrase[c])
    }
  }

  const L = uniqueSymbols.length

  // entropy bits    H = Log2( N^L )
  return Math.round(Math.log(Math.pow(N, L)) / Math.log(2))
}

function getPasswordCriteria() {
  return [
    {
      Description: 'A non-empty password.',
      Enforced: true,
      Entropy: 0,
      Grade: 1,
      Pattern: '.+',
    },
    {
      Description:
        '8-50 total characters. Has at least 2 character types: upper, lower, numeric, special.  Excludes common control characters.',
      Enforced: true,
      Entropy: 0,
      Grade: 2,
      Pattern:
        '(?:((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])))(?:^[^\n\t\r\v\f]{8,50}$)(?![\r\n])',
    },
    {
      Description: 'At least 32 bit entropy',
      Enforced: false,
      Entropy: 48,
      Grade: 3,
      Pattern: '.+',
    },
    {
      Description: 'At least 64 bit entropy',
      Enforced: false,
      Entropy: 64,
      Grade: 4,
      Pattern: '.+',
    },
  ]
}
