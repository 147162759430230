import { OrderValidationNameList } from '@/constants'
import toStringTime from '@/filters/toStringTime'
import analyticsService from '@/services/analyticsService'
import orderService from '@/services/orderService'
import { Address } from '@/store/models/deliveryModels'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import * as types from '@/store/mutation-types/orderMutations'
import * as promotionTypes from '@/store/mutation-types/orderPromotionMutations'
import { GET_CUSTOMER } from '@/store/mutation-types/userMutations'
import {
  deepClone,
  get,
  interpolate,
  getDateInTimeZone,
} from '@/utilityFunctions'
import * as orderStatus from '@/constants/orderStatuses'
import { getSession } from '@/services/seonService'

const state = {
  currentOrder: {
    orderDetail: {
      orderItems: [],
      paymentItems: [],
      promotionItems: [],
    },
  },
  orderValidationErrors: [],
  isCurrentOrderValid: true,
  loaderName: 'order',
  submittedOrder: {},
  isSubmittedOrderChangeable: false,
  isCartLoading: false,
  isOrderBeingSubmitted: false,
  intakeLoadError: null,
  promotions: {
    exception: null,
    pending: false,
  },
}

const getters = {
  currentOrder: (state) => state.currentOrder,
  submittedOrder: (state) => state.submittedOrder,
  isCurrentOrderValid: (state) => state.isCurrentOrderValid,
  isSubmittedOrderChangeable: (state) => state.isSubmittedOrderChangeable,
  isOrderBeingSubmitted: (state) => state.isOrderBeingSubmitted,
  intakeValidation: (state) => {
    // intakeErrors is always set to an array regardless of order validity
    const intakeErrors = state.orderValidationErrors.filter(
      (err) => err.name !== OrderValidationNameList.menuItem,
    )

    const restaurantInvalid = intakeErrors.some(
      (err) =>
        err.name === OrderValidationNameList.restaurantMissing ||
        err.name === OrderValidationNameList.restaurantInvalid,
    )
    const dateInvalid = intakeErrors.some(
      (err) =>
        err.name === OrderValidationNameList.deliveryDate ||
        err.name === OrderValidationNameList.pickupDate,
    )
    const timeInvalid = intakeErrors.some(
      (err) =>
        err.name === OrderValidationNameList.deliveryTime ||
        err.name === OrderValidationNameList.pickupTime,
    )

    return {
      isRestaurantValid: !restaurantInvalid,
      isDateValid: !dateInvalid,
      isTimeValid: !timeInvalid,
    }
  },
  intakeLoadError: (state) => state.intakeLoadError,
  menuErrors: (state) =>
    state.orderValidationErrors.filter((err) => {
      return err.name === OrderValidationNameList.menuItem
    }),
  intakeInfo: (state, _getters, _rootState, rootGetters) => {
    if (
      !state.currentOrder ||
      !state.currentOrder.orderDetail ||
      !state.currentOrder.orderDetail.store ||
      !state.currentOrder.orderDetail.store.storeNumber
    ) {
      return null
    }

    const detail = state.currentOrder.orderDetail

    const intakeData = {
      savedRestaurantId: detail.store.storeNumber,
      isPickup: !detail.isDelivery,
      pickupTime: { text: '', value: '', hours: 0, minutes: 0 },
      pickupDate: null,
      eventType: {
        showModal: false,
        selectedEventType: null,
      },
      selectedRestaurant: {},
      deliveryAddress: new Address(),
      // we are borrowing the store.name field since it is not mapped anywhere
      addressLine1: detail.store.name,
    }

    if (detail.isDelivery) {
      intakeData.deliveryAddress = {
        ...intakeData.deliveryAddress,
        ...detail.deliveryInfo.deliveryAddress,
      }
    }

    const pickupDate = detail.isDelivery
      ? detail.deliveryInfo.deliveryDate
      : detail.pickupInfo.pickupDate

    if (pickupDate) {
      const localPickupDate = getDateInTimeZone(
        pickupDate,
        detail.store.timezoneId,
      )
      intakeData.pickupDate = new Date(
        localPickupDate.getFullYear(),
        localPickupDate.getMonth(),
        localPickupDate.getDate(),
      )

      // text is the display value for the timepicker buttons.
      // value is the comparison value that will match the capacity value.
      // hours and minutes are exactly that.
      const dtString = pickupDate.toISOString()
      let matchFound = false
      const restaurantTimes = detail.isDelivery
        ? rootGetters.restaurantDeliveryTimes
        : rootGetters.restaurantPickupTimes
      for (const rt of restaurantTimes) {
        if (rt.value === dtString) {
          intakeData.pickupTime = { ...rt }
          matchFound = true
          break
        }
      }
      if (!matchFound) {
        intakeData.pickupTime = {
          text: toStringTime(localPickupDate),
          value: pickupDate.toISOString(),
          hours: localPickupDate.getHours(),
          minutes: localPickupDate.getMinutes(),
        }
      }
    }

    if (detail.eventType) {
      intakeData.eventType.selectedEventType = { code: detail.eventType }
    }

    return intakeData
  },
  isIntakeInUpdateMode: (state) => {
    return (
      state.currentOrder &&
      state.currentOrder.orderNumber &&
      state.currentOrder.orderNumber.length
    )
  },
  orderHasRestaurant: (state) => {
    return (
      (state.currentOrder &&
        state.currentOrder.orderDetail &&
        state.currentOrder.orderDetail.store &&
        state.currentOrder.orderDetail.store.storeNumber) ||
      false
    )
  },
  orderRestaurant: (state) => {
    if (
      !state.currentOrder ||
      !state.currentOrder.orderDetail ||
      !state.currentOrder.orderDetail.store
    ) {
      return null
    }

    return state.currentOrder.orderDetail.store
  },
  orderItems: (state, getters) => getOrderItems(getters.currentOrder),
  popularBuildItems: (state, getters) =>
    getPopularBuildItems(getters.currentOrder),
  orderHasItems: (state, getters) => getHasOrderItems(getters.currentOrder),
  submittedOrderItems: (state, getters) =>
    getOrderItems(getters.submittedOrder),
  submittedPopularBuildItems: (state, getters) =>
    getPopularBuildItems(getters.submittedOrder),
  orderItemsWithMenuData: (state, getters, rootState, rootGetters) => {
    return mergeOrderItemsWithMenuData(
      getters.orderItems,
      getters.popularBuildItems,
      rootGetters.getMenuItem,
    )
  },
  submittedOrderItemsWithMenuData: (state, getters, rootState, rootGetters) => {
    return mergeOrderItemsWithMenuData(
      getters.submittedOrderItems,
      getters.submittedPopularBuildItems,
      rootGetters.getConfirmationRestaurantMenuItem,
    )
  },
  orderIsDelivery: (state, getters) => getIsDelivery(getters.currentOrder),
  orderExceedsDeliveryMax: (state, getters) => {
    const { orderDetail } = state.currentOrder
    // If deliveryLimit is not configured, default to 0 (not allowing any delivery orders)
    let subtotal = (orderDetail && orderDetail.subTotal) || 0
    if (
      getters.currentOrderPromotionSubtotal &&
      getters.currentOrderPromotionSubtotal > 0
    ) {
      subtotal = getters.currentOrderPromotionSubtotal
    }
    return subtotal > (parseInt(process.env.VUE_APP_DELIVERY_LIMIT) || 0)
  },
  isCartLoading: (state) => state.isCartLoading,
  getCanBeReordered:
    (state, getters, rootState, rootGetters) => (completedOrder) => {
      if (
        !(
          completedOrder.status === orderStatus.CLOSED &&
          completedOrder.items.length
        )
      ) {
        return false
      }
      // If order exists in cart should use assoc restaurant menu, otherwise generic national menu
      const { allTieredMenu: menu } = rootGetters
      return completedOrder.items.every((orderItem) =>
        menu.some(
          (menuItem) =>
            menuItem.id === orderItem.menuId &&
            typeof menuItem.minPrice === 'number' &&
            orderItem.subItems.every((orderSubItem) =>
              menuItem.allItems.some(
                (menuSubItem) => menuSubItem.id === orderSubItem.menuId,
              ),
            ),
        ),
      )
    },
  // Promotions
  currentOrderHasPromotionCode: (state, getters) =>
    getHasPromotion(getters.currentOrder),
  currentOrderHasValidPromotionCode: (state, getters) =>
    getters.currentOrderHasPromotionCode &&
    !getters.currentOrderPromotionCodeIsInvalid,
  currentOrderPromotionCode: (state, getters) =>
    getPromotionCode(getters.currentOrder),
  currentOrderPromotionName: (state, getters) =>
    getPromotionName(getters.currentOrder),
  currentOrderPromotionCodeIsInvalid: (state) => !!state.promotions.exception,
  currentOrderPromotionDeduction: (state, getters) => {
    if (!getters.currentOrderHasValidPromotionCode) {
      return 0
    }
    return getPromotionDeduction(getters.currentOrder)
  },
  currentOrderPromotionSubtotal: (state, getters) => {
    if (state.currentOrder && state.currentOrder.orderDetail) {
      return (
        (state.currentOrder.orderDetail.subTotal || 0) -
        (getters.currentOrderPromotionDeduction || 0)
      )
    }
    return 0
  },
  currentOrderPromotionException: (state) => state.promotions.exception,
  pendingOrderPromotionChange: (state) => state.promotions.pending,
  submittedOrderHasPromotion: (state, getters) =>
    getHasPromotion(getters.submittedOrder),
  submittedOrderPromotionName: (state, getters) =>
    getPromotionName(getters.submittedOrder),
  submittedOrderPromotionDeduction: (state, getters) =>
    getPromotionDeduction(getters.submittedOrder),
}

const actions = {
  [types.GET_CART]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const orderId = rootState.user.cateringEditOrderId
      const handler = orderId
        ? orderService.getOrder(orderId)
        : orderService.getCart()

      commit(types.UPDATE_CART_LOADER, true)
      handler
        .then((response) => {
          if (response.data) {
            commit(types.UPDATE_ORDER, response.data)
          }

          resolve()
        })
        .catch((e) => {
          // An exception here means that the cart is no longer authorized.
          // Remove the no-longer-valid current order from state.
          commit(types.UPDATE_ORDER, {})
          reject(e)
        })
        .finally(() => {
          commit(types.UPDATE_CART_LOADER, false)
        })
    })
  },
  [types.SAVE_INTAKE]({ commit, dispatch, state, rootState, getters }, intake) {
    // create a deep clone to prevent mutating state
    const order = deepClone(state.currentOrder)
    const isUpdate = getters.isIntakeInUpdateMode

    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: (isUpdate ? 'Updating ' : 'Creating ') + 'Order',
      subText: '',
    })

    return (
      isUpdate
        ? orderService.updateOrder(mapIntakeToOrder(intake, order))
        : orderService.createOrder(mapIntakeToOrder(intake))
    )
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
        const eventType = intake.eventType.selectedEventType
          ? intake.eventType.selectedEventType.displayName
          : ''
        analyticsService.saveEventTypeSuccess(eventType)
        if (getters.orderIsDelivery) {
          analyticsService.deliveryDateTimeSuccess()
        } else {
          analyticsService.pickupDateTimeSuccess()
        }
        return response.data
      })
      .catch((e) => {
        // Upon an error, only clear the order if it is a new intake.
        // For edit order, leave the order as is.
        if (!rootState.user.cateringEditOrderId) {
          commit(types.UPDATE_ORDER, {})
        }
        throw e
      })
      .finally(() =>
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName }),
      )
  },
  [types.SAVE_ORDER_ITEM]({ commit, dispatch, state }, orderItem) {
    return new Promise((resolve, reject) => {
      const order = state.currentOrder
      const isUpdate = orderItem.orderItemId

      const updateOrCreate = isUpdate
        ? orderService.updateOrderItem.bind(null, order.id, orderItem)
        : orderService.createOrderItem.bind(null, order.id, orderItem)

      const subText = isUpdate
        ? 'Updating item in order'
        : 'Adding item to order'

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText,
      })

      updateOrCreate()
        .then((response) => {
          commit(types.UPDATE_ORDER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.REORDER]({ commit, dispatch, state }, { orderId, reorderId }) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Reordering',
    })
    return orderService
      .reorder(orderId, reorderId)
      .then(({ data: order }) => {
        commit(types.UPDATE_ORDER, order)
        return order
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.ADD_POPULAR_BUILD](
    { commit, dispatch, state },
    { orderId, popularBuildId, headcount },
  ) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Updating Order',
      subText: 'Adding popular build to order',
    })
    return orderService
      .addPopularBuildToOrder(orderId, popularBuildId, headcount)
      .then(({ data: order }) => {
        commit(types.UPDATE_ORDER, order)
        const popularBuildItem = order.orderDetail.popularBuildItems.filter(
          (ppi) => ppi.popularBuildId === popularBuildId.toLowerCase(),
        )[0]
        analyticsService.addPopularBuildToBag(
          popularBuildItem && popularBuildItem.name,
        )
        return order
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.DELETE_ORDER_ITEM]({ commit, dispatch, state }, orderItemId) {
    if (!orderItemId) {
      return Promise.resolve()
    }

    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Updating Order',
      subText: 'Removing item from order',
    })

    const order = state.currentOrder

    return orderService
      .deleteOrderItem(order.id, orderItemId)
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.SAVE_CUSTOMER]({ commit, dispatch, state }, customer) {
    return new Promise((resolve, reject) => {
      if (!state.currentOrder || !state.currentOrder.id) {
        resolve()
        return
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText: 'Updating Customer Info',
      })

      const order = deepClone(state.currentOrder)
      order.customer = customer

      orderService
        .updateOrder(order)
        .then((response) => {
          commit(types.UPDATE_ORDER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.SAVE_PICKUP_INFO]({ commit, dispatch, state }, pickupInfo) {
    return new Promise((resolve, reject) => {
      if (!state.currentOrder || !state.currentOrder.id) {
        resolve()
        return
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText: 'Updating Pickup Info',
      })

      const order = deepClone(state.currentOrder)
      order.orderDetail.pickupInfo = {
        ...order.orderDetail.pickupInfo,
        ...pickupInfo,
      }

      orderService
        .updateOrder(order)
        .then((response) => {
          commit(types.UPDATE_ORDER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.SAVE_ORDER_DELIVERY_ADDRESS](
    { commit, dispatch, state },
    deliveryAddress,
  ) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Updating Order',
      subText: 'Updating Delivery Address',
    })

    const order = deepClone(state.currentOrder)
    order.orderDetail.deliveryInfo.deliveryAddress = {
      ...order.orderDetail.deliveryInfo.deliveryAddress,
      ...deliveryAddress,
    }

    return orderService
      .updateOrder(order)
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
      })
      .finally(() =>
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName }),
      )
  },
  [types.VALIDATE_ORDER]({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (!state.currentOrder || !state.currentOrder.id) {
        commit(types.UPDATE_ORDER_VALIDITY, { isValid: false })
        resolve()
        return
      }

      orderService
        .validateOrder(state.currentOrder.id)
        .then(() => {
          // validate endpoint returns a 204
          commit(types.UPDATE_ORDER_VALIDITY, { isValid: true })
          resolve()
        })
        .catch((e) => {
          commit(types.UPDATE_ORDER_VALIDITY, {
            isValid: false,
            validationErrors: e.validationErrors,
          })
          reject(e)
        })
    })
  },
  [types.TRANSFER_CART]({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (!state.currentOrder || !state.currentOrder.id) {
        resolve()
        return
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText: 'Applying Changes',
      })

      orderService
        .transferOrder(state.currentOrder.id)
        .then((response) => {
          commit(types.UPDATE_ORDER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.TRANSFER_ORDER]({ commit, dispatch, state }, orderId) {
    return new Promise((resolve, reject) => {
      if (!orderId) {
        resolve()
        return
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText: 'Applying Changes',
      })

      orderService
        .transferOrder(orderId)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.SUBMIT_ORDER]({ commit, dispatch, state, getters }, paymentInfo) {
    commit(types.SET_IS_ORDER_BEING_SUBMITTED, true)
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Submitting Order',
      subText: 'Processing',
      includeOverlay: true,
    })

    return new Promise((resolve, reject) => {
      getSession()
        .then((seonSession) => {
          orderService
            .submitOrder(state.currentOrder.id, { ...paymentInfo, seonSession })
            .then(() => {
              analyticsService.ecommercePurchaseSuccess(getters.currentOrder)
              analyticsService.checkoutSuccessUserType(getters.isFullUser)
              analyticsService.checkoutSuccessOrderType(getters.orderIsDelivery)
              resolve()
            })
            .catch((e) => {
              reject(e)
            })
            .finally(() => {
              dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
              commit(types.SET_IS_ORDER_BEING_SUBMITTED, false)
              if (getters.currentOrderHasValidPromotionCode) {
                analyticsService.checkoutOrderWithPromotion()
              }
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  [types.CLEAR_ORDER]({ commit }) {
    commit(types.CLEAR_ORDER)
  },
  [types.GET_SUBMITTED_ORDER]({ commit, dispatch, state }, orderId) {
    return orderService.getOrder(orderId).then((response) => {
      commit(types.SET_SUBMITTED_ORDER, response.data)
    })
  },
  [types.UPDATE_DELIVERY_TIP]({ commit, dispatch, state }, deliveryInfo) {
    return new Promise((resolve, reject) => {
      if (!state.currentOrder || !state.currentOrder.id) {
        resolve()
        return
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Order',
        subText: 'Updating Delivery Tip',
      })

      const order = deepClone(state.currentOrder)

      // if custom then value is the amount, else the value is a percentage
      if (deliveryInfo.isCustom) {
        order.orderDetail.deliveryInfo.deliveryTipPercent = 0
        order.orderDetail.deliveryInfo.deliveryTip = deliveryInfo.value
      } else {
        order.orderDetail.deliveryInfo.deliveryTipPercent = deliveryInfo.value
      }

      orderService
        .updateOrder(order)
        .then((response) => {
          commit(types.UPDATE_ORDER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.CANCEL_SUBMITTED_ORDER]({ dispatch, state }, orderId) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Canceling Order',
        subText: '',
      })

      orderService
        .cancelOrder(orderId)
        .then(() => {
          resolve()
        })
        .catch(reject)
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.GET_IS_CHANGEABLE_SUBMITTED_ORDER]({ commit }, orderId) {
    return new Promise((resolve) => {
      // if submitted order is changeable, service will return 200
      let isChangeable
      orderService
        .isChangeable(orderId)
        .then(() => {
          isChangeable = true
        })
        .catch((e) => {
          // 404 or 400 (validation errors) here but regardless of
          // what it is, we do not want the submitted order to be editable.
          isChangeable = false
        })
        .finally(() => {
          commit(types.SET_IS_CHANGEABLE_SUBMITTED_ORDER, isChangeable)
          // always resolve here since it is only setting a boolean value
          resolve()
        })
    })
  },
  [types.MARK_SUBMITTED_ORDER_FOR_UPDATE]({ commit, dispatch }, orderId) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Getting order to edit',
        subText: '',
      })

      orderService
        .markSubmittedOrderForUpdate(orderId)
        .then(() => {
          dispatch(GET_CUSTOMER)
            .catch(() => {})
            .finally(() => {
              resolve()
            })
        })
        .catch(reject)
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.UNDO_SUBMITTED_ORDER_FOR_UPDATE]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Getting original order',
        subText: '',
      })

      orderService
        .undoSubmittedOrderForUpdate()
        .then(() => {
          dispatch(GET_CUSTOMER)
            .catch(() => {})
            .finally(() => {
              commit(types.CLEAR_ORDER)
              resolve()
            })
        })
        .catch(reject)
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.SET_INTAKE_LOAD_ERROR]({ commit }, error) {
    commit(types.SET_INTAKE_LOAD_ERROR, error)
  },
  [types.REFRESH_ORDER_PRICE]({ commit, dispatch, state }) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Updating Order',
      subText: 'Refreshing Order Prices',
    })
    // Updating order will always refresh the prices
    return orderService
      .updateOrder(state.currentOrder)
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [promotionTypes.APPLY_ORDER_PROMOTION](
    { commit, dispatch },
    { orderId, promoCode },
  ) {
    // Both apply and remove will always be called after validate so the promotion
    // state will automatically be reset
    commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, true)

    return orderService
      .applyOrderPromotion(orderId, promoCode)
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
        // This will reset the promotion state, no need to call it explicitly here.
        return dispatch(promotionTypes.VALIDATE_ORDER_PROMOTIONS, orderId)
      })
      .catch((err) => {
        commit(promotionTypes.ORDER_PROMOTION_EXCEPTION, err)
        throw err
      })
      .finally(() => {
        commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, false)
      })
  },
  [promotionTypes.REMOVE_ORDER_PROMOTION](
    { commit, dispatch },
    { orderId, promoCode },
  ) {
    commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, true)

    return orderService
      .removeOrderPromotion(orderId, promoCode)
      .then((response) => {
        commit(types.UPDATE_ORDER, response.data)
        // This will reset the promotion state, no need to call it explicitly here.
        return dispatch(promotionTypes.VALIDATE_ORDER_PROMOTIONS, orderId)
      })
      .catch((err) => {
        commit(promotionTypes.ORDER_PROMOTION_EXCEPTION, err)
        throw err
      })
      .finally(() => {
        commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, false)
      })
  },
  [promotionTypes.VALIDATE_ORDER_PROMOTIONS]({ commit, dispatch }, orderId) {
    // Reset the validation first
    commit(promotionTypes.READY_FOR_ORDER_PROMOTION_ENTRY)
    commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, true)

    return orderService
      .validateAllOrderPromotions(orderId)
      .then(() => {
        // Unless we get an exception, we'd expect a 204 here and no state
        // to update since the validation exceptions are the only ones being
        // checked for explicitly.
      })
      .catch((err) => {
        commit(promotionTypes.ORDER_PROMOTION_EXCEPTION, err)
        throw err
      })
      .finally(() => {
        commit(promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE, false)
      })
  },
}
const mutations = {
  [types.UPDATE_ORDER](state, newOrder) {
    if (
      newOrder.orderDetail &&
      newOrder.orderDetail.pickupInfo &&
      newOrder.orderDetail.pickupInfo.pickupDate
    ) {
      newOrder.orderDetail.pickupInfo.pickupDate = new Date(
        newOrder.orderDetail.pickupInfo.pickupDate,
      )
    }

    if (
      newOrder.orderDetail &&
      newOrder.orderDetail.deliveryInfo &&
      newOrder.orderDetail.deliveryInfo.deliveryDate
    ) {
      newOrder.orderDetail.deliveryInfo.deliveryDate = new Date(
        newOrder.orderDetail.deliveryInfo.deliveryDate,
      )
    }

    state.currentOrder = newOrder
  },
  [types.CLEAR_ORDER](state) {
    const emptyOrder = {
      orderDetail: {
        orderItems: [],
      },
    }
    state.currentOrder = emptyOrder
    state.isCurrentOrderValid = true
    state.orderValidationErrors = []
  },
  [types.UPDATE_ORDER_VALIDITY](state, data) {
    state.isCurrentOrderValid = data.isValid

    state.orderValidationErrors = data.validationErrors || []
  },
  [types.SET_SUBMITTED_ORDER](state, order) {
    state.submittedOrder = order
  },
  [types.UPDATE_CART_LOADER](state, newValue) {
    state.isCartLoading = newValue
  },
  [types.SET_IS_CHANGEABLE_SUBMITTED_ORDER](state, isChangeable) {
    state.isSubmittedOrderChangeable = isChangeable
  },
  [types.SET_IS_ORDER_BEING_SUBMITTED](state, isBeingSubmitted) {
    state.isOrderBeingSubmitted = isBeingSubmitted
  },
  [types.SET_INTAKE_LOAD_ERROR](state, error) {
    state.intakeLoadError = error
  },
  [promotionTypes.ORDER_PROMOTION_EXCEPTION](state, err) {
    state.promotions.exception = err
  },
  [promotionTypes.ORDER_PROMOTION_IS_PENDING_CHANGE](state, pending) {
    state.promotions.pending = pending
  },
  [promotionTypes.READY_FOR_ORDER_PROMOTION_ENTRY](state) {
    state.promotions.exception = null
    state.promotions.pending = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

export function mergeOrderItemsWithMenuData(
  orderItems,
  popularBuildItems,
  getMenuItemFn,
) {
  return orderItems.map((orderItem) => {
    const menuItem = getMenuItemFn(orderItem.menuId)
    const orderItemWithPrice = {
      ...orderItem,
      price: getOrderItemPrice(orderItem),
    }
    const data = { item: orderItemWithPrice, menu: menuItem }
    const servesText = interpolate(menuItem.formatServesText, data)
    const displayName =
      getPopularBuildDisplayName(orderItem, popularBuildItems) ||
      getFormattedDisplayName(menuItem, data)
    return { ...orderItem, servesText, displayName }
  })
}

function getFormattedDisplayName(menuItem, data) {
  return menuItem.formatDisplayName
    ? interpolate(menuItem.formatDisplayName, data)
    : menuItem.displayName
}

function getPopularBuildDisplayName(orderItem, popularBuildItems) {
  if (orderItem.popularBuildName) {
    return orderItem.popularBuildName
  }
  const popularBuildItem = orderItem.popularBuildItemId
    ? popularBuildItems.filter(
        (x) => x.popularBuildItemId === orderItem.popularBuildItemId,
      )[0]
    : null

  return popularBuildItem && popularBuildItem.name
}

function getOrderItemPrice(orderItem) {
  if (!orderItem.isOrderAmountFromSubItems) {
    return orderItem.price || 0
  }
  const subItemsWithPrice = orderItem.subItems.filter(
    (subItem) => subItem.price,
  )
  return subItemsWithPrice.length ? subItemsWithPrice[0].price : 0
}

// TODO: Refactor with higher order getters, maintaining contract for now
function getOrderItems(order) {
  return get('orderDetail.orderItems', order, [])
}

function getHasOrderItems(order) {
  return getOrderItems(order).length > 0
}

function getPopularBuildItems(order) {
  return get('orderDetail.popularBuildItems', order, [])
}

function getIsDelivery(order) {
  return get('orderDetail.isDelivery', order, false)
}

function getPromotion(order) {
  return get('orderDetail.promotionItems', order, [])[0] || null
}

function getHasPromotion(order) {
  return !!getPromotion(order)
}

function getPromotionName(order) {
  return get('name', getPromotion(order), '')
}

function getPromotionCode(order) {
  return get('code', getPromotion(order), '')
}

function getPromotionDeduction(order) {
  return getOrderItems(order)
    .map((item) => item.promotionDeduction || 0)
    .reduce((sum, num) => sum + num, 0)
}
// End TODO

// TODO: get rid of intake concept.  Mapping order to form state should happen at component level.
// Simply moving this here for now
export function mapIntakeToOrder(intake, order) {
  order = order || {
    orderDetail: {
      store: {
        storeNumber: null,
        name: null,
        timezoneId: null,
      },
      eventType: null,
      isDelivery: null,
      pickupInfo: {
        pickupDate: null,
      },
      deliveryInfo: {
        deliveryDate: null,
        deliveryTipPercent: 0.15,
        deliveryTip: 0,
        deliveryQuote: null,
        deliveryAddress: {
          addressName: null,
          firstName: null,
          lastName: null,
          company: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          postalCode: null,
          country: null,
          latitude: null,
          longitude: null,
          phone: null,
          instructions: null,
        },
      },
    },
  }

  // if selected restaurant, update the restaurant
  if (intake.selectedRestaurant && intake.selectedRestaurant.restaurantNumber) {
    order.orderDetail.store.storeNumber =
      intake.selectedRestaurant.restaurantNumber
    order.orderDetail.store.name =
      intake.selectedRestaurant.addresses[0].addressLine1
    order.orderDetail.store.timezoneId =
      intake.selectedRestaurant.timezone.timezoneId
  } else if (!intake.selectedRestaurant) {
    // null out the store
    order.orderDetail.store.storeNumber = null
    order.orderDetail.store.name = null
    order.orderDetail.store.timezoneId = null
  }
  order.orderDetail.isDelivery = intake ? !intake.isPickup : false

  // reset the date state before mapping
  order.orderDetail.pickupInfo.pickupDate = null
  order.orderDetail.deliveryInfo.deliveryDate = null

  if (intake.isPickup) {
    if (intake.pickupDate) {
      // default to intake.pickupDate, which will be the initial date that the order came with
      // intake.pickupTime will have the user selected datetime value from timepicker
      let pickupDateTime = intake.pickupDate
      if (intake.pickupTime && intake.pickupTime.value) {
        pickupDateTime = intake.pickupTime.value
      }
      order.orderDetail.pickupInfo.pickupDate = pickupDateTime
    }
  } else {
    if (intake.pickupDate) {
      // default to intake.pickupDate, which will be the initial date that the order came with
      // intake.pickupTime will have the user selected datetime value from timepicker
      let deliveryDate = intake.pickupDate
      if (intake.pickupTime && intake.pickupTime.value) {
        deliveryDate = intake.pickupTime.value
      }
      order.orderDetail.deliveryInfo.deliveryDate = deliveryDate

      if (intake.deliveryAddress) {
        order.orderDetail.deliveryInfo.deliveryAddress = {
          ...order.orderDetail.deliveryInfo.deliveryAddress,
          ...intake.deliveryAddress,
        }
      }
    }

    // This block is for the scenario when a pickup is changed to delivery,
    // apply the default tip percentage and clear the tip amount
    if (intake.applyDefaultDeliveryTipPercent) {
      order.orderDetail.deliveryInfo.deliveryTipPercent = 0.15
      order.orderDetail.deliveryInfo.deliveryTip = 0
    }

    if (intake.deliveryQuote) {
      order.orderDetail.deliveryInfo.deliveryQuote = intake.deliveryQuote
    }
  }

  if (intake.eventType.selectedEventType) {
    order.orderDetail.eventType = intake.eventType.selectedEventType.code
  }
  return order
}
