<template>
  <div class="login-control">
    <b-form @submit="performLogin" novalidate>
      <inputbox
        id="emailInput"
        type="text"
        v-model="email"
        placeholder="Email"
        autoComplete="username"
        :required="true"
        :validityState="validityState"
        :max-length="50"
        :rules="[
          rules.required('Email is required'),
          rules.emailRegEx('Email is invalid', false, $mq, true),
        ]"
      />

      <inputbox
        id="passwordInput"
        :type="passwordFieldType"
        v-model="password"
        placeholder="Password"
        autoComplete="current-password"
        :required="true"
        :validityState="validityState"
        :max-length="50"
        :rules="[rules.required('Password is required')]"
      >
        <template slot="icon">
          <eye-open-svg
            v-show="!shouldShowPassword"
            aria-label="Show password"
            :aria-hidden="shouldShowPassword"
            role="button"
            tabindex="0"
            @eyeopensvg-click="showPassword"
            @keydown.native.enter="showPassword"
          />
          <eye-closed-svg
            v-show="shouldShowPassword"
            aria-label="Hide password"
            :aria-hidden="!shouldShowPassword"
            role="button"
            tabindex="0"
            @eyeclosedsvg-click="hidePassword"
            @keydown.native.enter="hidePassword"
          />
        </template>
      </inputbox>

      <div class="remember-line">
        <div class="select-remember">
          <b-form-checkbox v-model="shouldRememberMe">
            Remember me
          </b-form-checkbox>
        </div>
        <div
          class="forgot-password"
          role="button"
          tabindex="0"
          @click="forgotPassword"
          @keydown.enter="forgotPassword"
        >
          FORGOT PASSWORD?
        </div>
      </div>

      <div class="error-container error">{{ errorMessage }}</div>

      <b-button
        variant="primary"
        block
        size="md"
        class="login-btn"
        type="submit"
      >
        SIGN IN
      </b-button>
    </b-form>
  </div>
</template>

<script>
import eyeOpenSvg from '@/components/common/EyeOpenSvg'
import eyeClosedSvg from '@/components/common/EyeClosedSvg'
import { LOGIN } from '@/store/mutation-types/userMutations'
import inputbox from '@/components/common/Inputbox'
import {
  emailRegEx,
  required,
  validityStateInitialize,
} from '@/validators/index'
import { GET_ALL_REWARDS_DATA } from '@/store/mutation-types/rewardsMutations'
import {
  GET_CART,
  TRANSFER_CART,
  VALIDATE_ORDER,
} from '@/store/mutation-types/orderMutations'
import { GET_RESTAURANT_MENU } from '@/store/mutation-types/restaurantMenuMutations'
import { LOGIN_ACCOUNT_VERIFICATION_INITIALIZE } from '@/store/mutation-types/accountVerificationMutations'
import { INTAKE_DETAIL } from '@/router/routeNames'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import * as verificationErrorTypes from '@/constants/accountVerificationErrorTypes'
import {
  AccountLockedError,
  PhoneVerificationUnexpectedError,
} from '@/constants'
import { ERROR_VERIFICATION_ACCOUNT_LOCKED } from '@/constants/errorCodes'

export default {
  name: 'login-control',
  components: {
    eyeOpenSvg,
    eyeClosedSvg,
    inputbox,
  },
  data() {
    return {
      shouldShowPassword: false,
      shouldRememberMe: false,
      errorMessage: null,
      email: null,
      password: null,
      rules: {
        emailRegEx,
        required,
      },
    }
  },
  computed: {
    validityState() {
      return validityStateInitialize()
    },
    passwordFieldType() {
      return this.shouldShowPassword ? 'text' : 'password'
    },
  },
  methods: {
    showPassword() {
      this.shouldShowPassword = true
    },
    hidePassword() {
      this.shouldShowPassword = false
    },
    forgotPassword() {
      this.$emit('forgot-password-click')
    },
    postLoginSuccess() {
      // INTAKE will handle getting its own data since it is more involved
      // See Detail.vue -> watch -> $store.getters.isFullUser
      if (this.$store.state.route.name === INTAKE_DETAIL) {
        this.emitLoginSuccessAndResetForm()
        return
      }

      // fire and forget requests for rewards
      this.$store.dispatch(GET_ALL_REWARDS_DATA).catch(() => {})
      // This was originally in MainMenu -> emitLoginSuccessAndResetForm but it was unreliable
      // Apparently if the user closes the menu too early, then emitLoginSuccessAndResetForm never executes
      // So moving this code here will ensure it runs
      let postLoginAction
      if (!this.$store.getters.intakeInfo) {
        // if the user hasn't created a guest session yet,
        // get their logged-in cart.
        postLoginAction = this.$store
          .dispatch(GET_CART)
          .then(() => {
            const { storeNumber } =
              this.$store.getters.currentOrder.orderDetail.store
            return Promise.all([
              this.$store.dispatch(GET_RESTAURANT_MENU, storeNumber),
              this.$store.dispatch(VALIDATE_ORDER),
            ])
          })
          .catch(() => {})
      } else {
        postLoginAction = this.$store.dispatch(TRANSFER_CART)
      }

      postLoginAction.finally(this.emitLoginSuccessAndResetForm)
    },
    performLogin(event) {
      event.stopPropagation()
      event.preventDefault()

      const isValid = this.validityState.isValid()

      if (!isValid) {
        return
      }

      this.errorMessage = null

      this.$store
        .dispatch(LOGIN, {
          username: this.email,
          password: this.password,
          shouldRemember: this.shouldRememberMe,
        })
        .then(() => {
          this.errorMessage = ''
          this.postLoginSuccess()
        })
        .catch((loginActionErrorResponse) => {
          if (
            loginActionErrorResponse.errorCode ===
            ERROR_VERIFICATION_ACCOUNT_LOCKED
          ) {
            this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
              ...AccountLockedError,
            })
            this.verificationFailed()
            return
          }

          this.$store
            .dispatch(LOGIN_ACCOUNT_VERIFICATION_INITIALIZE, {
              email: this.email,
              error: loginActionErrorResponse,
            })
            .then(() => {
              this.postLoginSuccess()
            })
            .catch((verificationActionErrorResponse) => {
              if (
                verificationActionErrorResponse.message ===
                verificationErrorTypes.ACCOUNT_VERIFICATION_UNKNOWN_ERROR
              ) {
                this.errorMessage =
                  'Bad username or password. Please try again or create an account.'
              } else {
                if (
                  verificationActionErrorResponse.message ===
                  verificationErrorTypes.ACCOUNT_VERIFICATION_INITIALIZATION_FAILED
                ) {
                  this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
                    ...PhoneVerificationUnexpectedError,
                  })
                }
                this.verificationFailed()
              }
            })
        })
    },
    emitLoginSuccessAndResetForm() {
      this.$emit('login-success')
      this.resetForm()
    },
    verificationFailed() {
      this.$emit('login-verification-failed')
      this.resetForm()
    },
    resetForm() {
      this.email = null
      this.password = null
      this.showPassword = false
      this.shouldRememberMe = false
    },
  },
}
</script>

<style lang="scss">
.login-control {
  form {
    width: 100%;
  }

  .form-group {
    & > div {
      position: relative;
    }

    .seeing-eye {
      position: absolute;
      top: 6px;
      left: 94%;
      cursor: pointer;

      path {
        fill: $catering-chocolate;
      }
      circle {
        stroke: $catering-chocolate;
      }
    }
  }

  input[type='text'],
  input[type='password'] {
    background-color: transparent;
    font-size: 19px;
    letter-spacing: normal;
  }

  .remember-line {
    padding-top: 15px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-remember {
      & label {
        padding-left: 12px;
        padding-top: 2px;
        font-size: 13px;
        color: $catering-grey-brown;
        height: 16px;
      }

      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::before,
      .custom-checkbox .custom-control-label::before,
      .custom-checkbox .custom-control-label::after,
      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::after {
        width: 25px;
        height: 25px;
        margin-top: -5px;
      }
    }

    .forgot-password {
      color: $catering-gold;
      margin-right: 10px;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .error-container {
    padding-bottom: 15px;
  }

  .login-btn {
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
