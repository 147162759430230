import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import accountVerification from '@/store/modules/accountVerification'
import builder from '@/store/modules/builder'
import confirmDialog from '@/store/modules/confirmDialog'
import countryCodes from '@/store/modules/countryCodes'
import customerDelivery from '@/store/modules/customerDelivery'
import delivery from '@/store/modules/delivery'
import drawers from '@/store/modules/drawers'
import epops from '@/store/modules/epops'
import eventTypes from '@/store/modules/eventTypes'
import googleMaps from '@/store/modules/googleMaps'
import homeMenu from '@/store/modules/homeMenu'
import loader from '@/store/modules/loader'
import order from '@/store/modules/order'
import popularBuilds from '@/store/modules/popularBuilds'
import restaurantData from '@/store/modules/restaurantData'
import restaurantMenu from '@/store/modules/restaurantMenu'
import rewards from '@/store/modules/rewards'
import transaction from '@/store/modules/transaction'
import uiCommon from '@/store/modules/uiCommon'
import user from '@/store/modules/user'
import userOrderHistory from '@/store/modules/userOrderHistory'
import versionCheck from '@/store/modules/versionCheck'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const state = {}
const plugins = []

if (debug) {
  plugins.push(createLogger())
}

export default new Vuex.Store({
  state,
  modules: {
    accountVerification,
    builder,
    confirmDialog,
    countryCodes,
    customerDelivery,
    delivery,
    drawers,
    epops,
    eventTypes,
    googleMaps,
    homeMenu,
    loader,
    order,
    popularBuilds,
    restaurantData,
    restaurantMenu,
    rewards,
    transaction,
    uiCommon,
    user,
    userOrderHistory,
    versionCheck,
  },
  plugins,
  strict: debug,
})
