import validation from '@/validators/accountValidationService'

export default (errorMessage, allowBlank, viewPort, isLogin = false) => (value) => {
  if(isLogin){
    return validation.isEmailValid(value, isLogin)
  }
  if (!value && allowBlank) {
    return true
  }
  if(value.includes('+')){
    if(viewPort && (viewPort === 'xs' || viewPort === 'sm')) {
      return "Email cannot contain the '+' symbol."
    } else {
      return "Please enter a valid email. Email cannot contain the '+' symbol."
    }
  }
  return validation.isEmailValid(value) || errorMessage
}
